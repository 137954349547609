import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Our Services - Shenzhen Starnoon Technology Co., LTD
			</title>
			<meta name={"description"} content={"At Shenzhen Starnoon Technology Co., LTD, we offer a suite of services tailored to create spectacular events that resonate with your personal style and exceed your expectations. Here's a glimpse into what we can bring to your special occasion."} />
			<meta property={"og:title"} content={"Our Services | Our Services - Shenzhen Starnoon Technology Co., LTD"} />
			<meta property={"og:description"} content={"At Shenzhen Starnoon Technology Co., LTD, we offer a suite of services tailored to create spectacular events that resonate with your personal style and exceed your expectations. Here's a glimpse into what we can bring to your special occasion."} />
			<meta property={"og:image"} content={"https://tlastpainter.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tlastpainter.com/img/icon-7.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://tlastpainter.com/img/icon-7.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://tlastpainter.com/img/icon-7.jpg"} />
		</Helmet>
		<Components.Eader />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Our Services
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					At Shenzhen Starnoon Technology Co., LTD, we offer a suite of services tailored to create spectacular events that resonate with your personal style and exceed your expectations. Here's a glimpse into what we can bring to your special occasion.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contact Us
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://tlastpainter.com/img/7.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
						Everything you need
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Begin Your Event Journey
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					For more detailed information on how Shenzhen Starnoon Technology Co., LTD can elevate your next event, or to start planning with us, please contact us directly. We are here to transform your event into an unforgettable experience.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Comprehensive Event Design
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							From conceptualization to execution, we craft the visual and thematic aspects of your event, ensuring every detail aligns with your vision.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Venue Selection and Management
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Our team assists in sourcing and securing the ideal venue, managing all related logistics and coordination.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Weddings and Celebrations
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Creating magical and memorable experiences for weddings, anniversaries, and milestone celebrations.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Corporate Events and Conferences
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Expertly managing corporate functions, conferences, and business events with a professional touch.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Vendor Coordination
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							We liaise with top-notch caterers, decorators, entertainers, and other suppliers, ensuring seamless service and quality.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Custom Catering and Menu Planning
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Tailoring food and beverage options to complement the theme and style of your event, accommodating all dietary preferences.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://tlastpainter.com/img/8.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Shenzhen Starnoon Technology Co., LTD - Crafting Celebrations
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Elegance Events Co. was born from a passion for bringing joy and beauty to life's special moments. Our journey in the event planning industry is marked by a dedication to creating experiences that are not just events, but cherished memories.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});